<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import {
  shopServiceMethods,
  notificationMethods
} from "@/state/helpers";

import {
  required, 
} from "vuelidate/lib/validators";

import { promotionService } from '../../../services/promotion.service';

const initForm = {
        title : null, 
        description : null,
        imgUrl : null,
        rewardType: null,
        rewardDiscount: null,
        minimumQualifyingAmount: null,
        minimumQualifyingCount: null,
        qualifyingAmountCumulative: null,
        createdByUserId:null,
        startDateTime: null,
        endDateTime: null,
        createdBy: JSON.parse(localStorage.getItem('user')).id,
      };

export default {
  page: {
    title: "Manage Promotions ",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      tableData: [],
      title: "Manage Promotions",
      items: [
        {
          text: "Smart Exchange",
          href: "/"
        },
        {
          text: "Shops",
          active: true
        }
      ],

      rewardTypes: [
        {
            title:"Refferal Based",
            value: "referral-reward"
        },
        {
            title:"Volume Based",
            value: "volume-transactions-rewards"
        }
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "title", sortable: true },
        { key: "description", sortable: true },
        { key: "rewardType", label:"Type",sortable: true },
        { key: "rewardDiscount", label:"Discount", sortable: true },
        { key: "minimumQualifyingAmount", label:"Amount", sortable: true },
        { key: "minimumQualifyingCount", label:"Count", sortable: true },
        { key: "qualifyingAmountCumulative", label:"Cumulative", sortable: true },
        { key: "startDateTime", label:"Start", sortable: true },
        { key: "endDateTime", label:"End", sortable: true },
        { key: "actions" }
      ],

      form: {
        title : null, 
        description : null,
        imgUrl : null,
        rewardType: null,
        rewardDiscount: null,
        minimumQualifyingAmount: null,
        minimumQualifyingCount: null,
        qualifyingAmountCumulative: true,
        createdByUserId:JSON.parse(localStorage.getItem('user')).id,
        startDateTime: null,
        endDateTime: null,
      },

      editForm: {
        id:"",
        title : "", 
        description : "",
        imgUrl : "",
        rewardType:"",
        rewardDiscount:0,
        minimumQualifyingAmount:0,
        minimumQualifyingCount:0,
        qualifyingAmountCumulative:false,
        createdByUserId:0,
        startDateTime:"",
        endDateTime:""
      },

      submitted: false,
      submitform: false,
      submit: false,
      notificationMessage:"",
      notificationType:null,
      noticationIcon: null,
      showform: false
    };
  },

  validations: {
    form: {
        title : {required}, 
        description : {required},
        rewardType:{required},
        rewardDiscount:{required},
        minimumQualifyingAmount:{required},
        minimumQualifyingCount:{required},
        qualifyingAmountCumulative:{required},
        startDateTime:{required},
        endDateTime:{required}
      },
  },

  created() {
    this.loadAllPromotions();
  },

  computed: {
    rows() {
      return this.tableData.length;
    },
    notification() {
        return this.$store ? this.$store.state.notification : null;
      }
  },
  mounted() {
    this.totalRows = this.items.length;
  },

  methods: { 
    ...shopServiceMethods,
    ...notificationMethods,

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    formSubmit() {

      console.log(this.form)

      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        // this.submitted = false;
        return;
      } else {
          const {form} = this;
          const promotionForm = {
                "operation" : "promotionCreation",
                "accessToken" : JSON.parse(localStorage.getItem('access_token')),
                "requestBody" : form
          }; 
          promotionService.addPromotion(promotionForm).then(result=>{
            if(result.status=="SUCCESS"){
              this.loadAllPromotions()
              this.notificationMessage = result.message;
              this.notificationType = "success";
              this.noticationIcon = "mdi-check-all";
              this.submitted = false;
              this.form = Object.assign({}, initForm);
            } else {
              this.notificationMessage = result.message;
              this.submitted = false;
              this.notificationType = "danger";
              this.noticationIcon = "mdi-block-helper";
            }
          });
      }
    },

    storeState(promotion){
      this.editForm = promotion
    },

    editPromotion() {
      const {editForm} = this;
      const promotionForm = {
            "operation" : "promotionCreation",
            "accessToken" : JSON.parse(localStorage.getItem('access_token')),
            "requestBody" : editForm
      }; 
      promotionService.addPromotion(promotionForm).then(result=>{
        if(result.status=="SUCCESS"){
          this.loadAllPromotions()
          this.notificationType = "success";
          this.noticationIcon = "mdi-check-all";
          this.notificationMessage = result.message;
          this.submitted = false;
          this.form = Object.assign({}, initForm);
        } else {
          this.submitted = false;
          this.notificationMessage = result.message;
          this.notificationType = "danger";
          this.noticationIcon = "mdi-block-helper";
        }
      });
  },

    async loadAllPromotions() {
        try {
        await promotionService.getAllPromotions().then(response=>{
          console.log(response);
              console.log("\n\n----------");
            if(response.responseBody.length>0){
              
                this.tableData = response.responseBody;
              }
          });
        } catch (error) {
          console.log(error);
        }
    },
  },

};

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">

      <b-modal id="modal-standard" title="Edit Shop" title-class="font-18" hide-footer>
        <b-alert
          :variant="notificationType" 
          class="mt-3"
          v-if="notificationMessage"
          show
          dismissible
        >
          <i :class="'mdi '+ noticationIcon + ' mr-2'"></i>{{notificationMessage}}
        </b-alert>
        <div class="card-body">

          <form class="needs-registration" @submit.prevent="editPromotion">

                <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label > Title</label>
                        <input
                          v-model="editForm.title"
                          type="text"
                          class="form-control"
                          placeholder="Promotion Title"
                        />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                          <label>Reward Type</label>
                          <select class="form-control" v-model="editForm.rewardType">
                            <option v-for="(rewardT, index) in rewardTypes" :value="rewardT.value" :key="index">{{rewardT.title}}</option>
                          </select>
                      </div>
                    </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label > Description</label>
                      <div>
                        <input
                          v-model="editForm.description"
                          type="text"
                          class="form-control"
                          placeholder="Enter Description"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Reward Discount</label>
                      <div>
                        <input
                          v-model="editForm.rewardDiscount"
                          type="text"
                          class="form-control"
                          placeholder="% Discount"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Qualifying Amount</label>
                      <div>
                        <input
                          v-model="editForm.minimumQualifyingAmount"
                          type="number"
                          class="form-control"
                          placeholder="Amount"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Transaction Count</label>
                      <div>
                        <input
                          v-model="editForm.minimumQualifyingCount"
                          type="number"
                          class="form-control"
                          placeholder="Count"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Is Qualifying Amount Cumulative?</label>
                      <section>
                        <input type="radio" v-model="editForm.qualifyingAmountCumulative" value="true"> Yes
                        <input type="radio" v-model="editForm.qualifyingAmountCumulative" value="false"> No
                    </section>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label >Reward Image Link</label>
                      <div>
                        <input
                          v-model="editForm.imgUrl"
                          type="text"
                          class="form-control"
                          placeholder="Paste reward image link"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Start Date</label>
                      <div>
                        <input
                          v-model="editForm.startDateTime"
                          type="date"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label >End Date</label>
                      <div>
                        <input
                          v-model="editForm.endDateTime"
                          type="date"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>

              <div class="spinner" v-if="submitted">
                <i class="ri-loader-line spin-icon"></i>
              </div>
              <button v-if="!submitted" class="btn btn-primary" type="submit">Update Promotion</button>
            </form>
        </div>
      </b-modal>

    <div class="col-md-4">
        <b-alert
          :variant="notificationType" 
          class="mt-3"
          v-if="notificationMessage"
          show
          dismissible
        >
          <i :class="'mdi '+ noticationIcon + ' mr-2'"></i>{{notificationMessage}}
        </b-alert>
          <a href="javascript:void(0);" class="btn btn-primary mb-2" @click="showform = true" v-if="!showform">
            <i class="mdi mdi-plus mr-2"></i> Add Promotion
          </a>

        <div class="card" v-if="showform">
          <div class="card-body">
            <form class="needs-registration" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label > Title</label>
                        <input
                          
                          v-model="form.title"
                          type="text"
                          class="form-control"
                          placeholder="Promotion Title"
                          :class="{ 'is-invalid': submitted && $v.form.title.$error }"
                        />
                        <div v-if="submitted && $v.form.title.$error" class="invalid-feedback">
                          <span v-if="!$v.form.title.required">This value is required.</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                          <label>Reward Type</label>
                          <select class="form-control" v-model="form.rewardType" :class="{ 'is-invalid': submitted && $v.form.rewardType.$error }">
                            <option v-for="(rewardT, index) in rewardTypes" :value="rewardT.value" :key="index">{{rewardT.title}}</option>
                          </select>
                          <div v-if="submitted && $v.form.rewardType.$error" class="invalid-feedback">
                            <span v-if="!$v.form.rewardType.required">This value is required.</span>
                          </div>
                      </div>
                    </div>

                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label > Description</label>
                      <div>
                        <input
                          v-model="form.description"
                          type="text"
                          class="form-control"
                          placeholder="Enter Description"
                          :class="{ 'is-invalid': submitted && $v.form.description.$error }"
                        />
                        <div v-if="submitted && $v.form.description.$error" class="invalid-feedback">
                          <span v-if="!$v.form.description.required">This value is required.</span> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Reward Discount</label>
                      <div>
                        <input
                          v-model="form.rewardDiscount"
                          type="text"
                          class="form-control"
                          placeholder="% Discount"
                          :class="{ 'is-invalid': submitted && $v.form.rewardDiscount.$error }"
                        />
                        <div v-if="submitted && $v.form.rewardDiscount.$error" class="invalid-feedback">
                          <span v-if="!$v.form.rewardDiscount.required">This value is required.</span> 
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Qualifying Amount</label>
                      <div>
                        <input
                          v-model="form.minimumQualifyingAmount"
                          type="number"
                          class="form-control"
                          placeholder="Amount"
                          :class="{ 'is-invalid': submitted && $v.form.minimumQualifyingAmount.$error }"
                        />
                        <div v-if="submitted && $v.form.minimumQualifyingAmount.$error" class="invalid-feedback">
                          <span v-if="!$v.form.minimumQualifyingAmount.required">This value is required.</span> 
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Transaction Count</label>
                      <div>
                        <input
                          v-model="form.minimumQualifyingCount"
                          type="number"
                          class="form-control"
                          placeholder="Count"
                          :class="{ 'is-invalid': submitted && $v.form.minimumQualifyingCount.$error }"
                        />
                        <div v-if="submitted && $v.form.minimumQualifyingCount.$error" class="invalid-feedback">
                          <span v-if="!$v.form.minimumQualifyingCount.required">This value is required.</span> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Is Qualifying Amount Cumulative?</label>
                      <section>
                        <input type="radio" v-model="form.qualifyingAmountCumulative" value="true"> Yes
                        <input type="radio" v-model="form.qualifyingAmountCumulative" value="false"> No
                    </section>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label >Reward Image Link</label>
                      <div>
                        <input
                          v-model="form.imgUrl"
                          type="text"
                          class="form-control"
                          placeholder="Paste reward image link"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Start Date</label>
                      <div>
                        <input
                          v-model="form.startDateTime"
                          type="date"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.startDateTime.$error }"
                        />
                        <div v-if="submitted && $v.form.startDateTime.$error" class="invalid-feedback">
                          <span v-if="!$v.form.startDateTime.required">This value is required.</span> 
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label >End Date</label>
                      <div>
                        <input
                          v-model="form.endDateTime"
                          type="date"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.endDateTime.$error }"
                        />
                        <div v-if="submitted && $v.form.endDateTime.$error" class="invalid-feedback">
                          <span v-if="!$v.form.endDateTime.required">This value is required.</span> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="spinner" v-if="submitted">
                <i class="ri-loader-line spin-icon"></i>
              </div>
              <button v-if="!submitted" class="btn btn-primary" type="submit">Add New Promotion</button>
            </form>
          </div>
        </div>
      </div>
       <div class="col-md-8">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
              <template #cell(actions)="row">
                <a
                  @click="storeState(row.item)"
                  href="javascript:void(0);"
                  class="mr-3 text-primary"
                  v-b-tooltip.hover
                  title="Edit"
                >
                  <i v-b-modal.modal-standard class="mdi mdi-pencil font-size-18" ></i>
                </a>
                <a
                  @click="row.toggleDetails"
                  href="javascript:void(0);"
                  class="text-danger"
                  v-b-tooltip.hover
                  title="Disable"
                >
                  <i class="mdi mdi-cancel font-size-18"></i>
                </a>

              </template>
      </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </Layout>
</template>